<template>
    <div v-if="!loading">
        <zw-table ref="table"
                  tableName="table_locales"
                  columnsPrefix="table-locales.column."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :sticky="false"
                  sticky-header="100%"
                  :actions-list="getActions()"
        >
            <template #cell(group)="row">
                {{ row.item.group }}
            </template>
            <template #cell(key)="row">
                {{ row.item.key }}
            </template>

            <template v-for="(locale) in locales"
                      #[`cell(`+locale+`)`]="row">
                <div v-if="!editing.includes(row.index)">
                    {{ row.item[locale] }}
                </div>
                <b-form-input v-else
                              v-model="row.item[locale]"
                              autocomplete="off"
                              @keyup.enter="()=>{save(row.index, row.item)}"
                ></b-form-input>
            </template>

            <template #cell(actions)="row">
                <b-button size="sm"
                          variant="primary"
                          v-if="!editing.includes(row.index)"
                          @click="()=>{addEditing(row.index)}"
                          :title="$t('common.title.edit')"
                >
                    <font-awesome-icon icon="edit"/>
                </b-button>
                <b-button size="sm"
                          variant="warning"
                          v-else
                          @click="()=>{save(row.index, row.item)}"
                >
                    <font-awesome-icon icon="save"/>
                </b-button>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'TranslationsTable',
    mixins: [commonSave, commonTable],
    data() {
        return {
            tableOptions: {
                sortBy: 'group',
                sortDesc: true,
            },
            locales: [],
            loading: true,
            saveFilters: true,
            editing: [],
        }
    },
    methods: {
        ...mapGetters('I18NStore', ['getMessagesTable']),
        shown() {
            this.loading = false
            this.$root.afterSettingsUpdate()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(
                ctx,
                'I18NStore/fetchMessagesTable',
                'getMessagesTable',
                {},
                (tableData) => {
                    this.editing = []
                    var item = tableData.data[0]
                    for (let key in item) {
                        if (item.hasOwnProperty(key)) {
                            if (key != 'group' && key != 'key') {
                                this.locales.push(key);
                            }
                        }
                    }
                }
            )
        },
        afterSave() {
            this.$store.dispatch('CommonData/fetchCommonData')
            this.shown()
            this.$root.afterSettingsUpdate()
        },
        addEditing(index) {
            this.editing.push(index);
        },
        save(index, item) {
            this.editing = this.editing.filter(item => item !== index);

            var translation = {}
            for (let key in item) {
                if (item.hasOwnProperty(key)) {
                    if (key != 'group' && key != 'key') {
                        translation[key] = item[key]
                    }
                }
            }
            this.$store.dispatch('I18NStore/saveTranslation', {
                group: item.group,
                key: item.key,
                translations: translation,
            })
                .then((response) => {
                    this.$set(translation, 'editing', false)
                })
                .catch(errors => {
                    this.$refs['observer'].setErrors(errors)
                    this.$root.showMessage(
                        this.$t('common.form.title.error'),
                        this.$t('common.toasts.validation_error'),
                        'danger'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getActions() {
            let actions = []
            return actions
        }
    },
    mounted() {
        this.shown()
    }
}
</script>